import { create, test, enforce, eager } from 'vest'
import { isEmail, containsLinks } from 'utils/src/validators'
import type { Microcopy } from 'utils/src/microcopy'
import { type Fields } from './formConfig'

const suite = create((inputs: Partial<Fields>, microcopy: Microcopy): void => {
  // fail on the first test of a field, for performance since we only show one error at a time
  eager()

  /**
   * First name
   */

  if ('firstName' in inputs) {
    test('firstName', microcopy.validation.required, () => {
      enforce(inputs.firstName).isNotBlank()
    })

    test('firstName', microcopy.validation.nameMinLength, () => {
      enforce(inputs.firstName).longerThanOrEquals(3)
    })

    test('firstName', microcopy.validation.nameMaxLength, () => {
      enforce(inputs.firstName).shorterThanOrEquals(255)
    })

    test('firstName', microcopy.validation.noLinks, () => {
      enforce(inputs.firstName).notMatches(containsLinks)
    })
  }

  /**
   * Surname
   */

  if ('surname' in inputs) {
    test('surname', microcopy.validation.required, () => {
      enforce(inputs.surname).isNotBlank()
    })

    test('surname', microcopy.validation.nameMinLength, () => {
      enforce(inputs.surname).longerThanOrEquals(3)
    })

    test('surname', microcopy.validation.nameMaxLength, () => {
      enforce(inputs.surname).shorterThanOrEquals(255)
    })

    test('surname', microcopy.validation.noLinks, () => {
      enforce(inputs.surname).notMatches(containsLinks)
    })
  }

  /**
   * Email
   */

  if ('email' in inputs) {
    test('email', microcopy.validation.required, () => {
      enforce(inputs.email).isNotBlank()
    })

    test('email', microcopy.validation.emailValid, () => {
      enforce(inputs.email).matches(isEmail)
    })
  }

  /**
   * Telephone
   */

  if ('telephone' in inputs) {
    test('telephone', microcopy.validation.required, () => {
      enforce(inputs.telephone).isNotBlank()
    })

    test('telephone', microcopy.validation.telephoneValid, () => {
      enforce(inputs.telephone).isNumeric()
    })
  }

  /**
   * Reason
   */

  if ('reason' in inputs) {
    test('reason', microcopy.validation.required, () => {
      enforce(inputs.reason).isNotBlank()
    })
  }

  /**
   * Comments
   */

  if ('comments' in inputs) {
    test('comments', microcopy.validation.required, () => {
      enforce(inputs.comments).isNotBlank()
    })

    test('comments', microcopy.internationalContactForm.commentsValidationMaxLength, () => {
      enforce(inputs.comments).shorterThanOrEquals(1000)
    })
  }

  /**
   * Terms
   */

  if ('terms' in inputs) {
    test('terms', microcopy.validation.required, () => {
      enforce(inputs.terms).isTruthy()
    })
  }
})

export default suite
