import React from 'react'
import styled from 'styled-components'
import { useStoreLocatorMap } from '@cw-monorepo/contentful/src/hooks/useStoreLocatorMap'

import isRtl from 'utils/src/rtl'
import { above } from 'ui/src/styles/index'
import extractLocaleFromUrl from '../../../helpers/extractLocaleFromUrl/extractLocaleFromUrl'
import locationPin from 'cw-frontend/src/assets/icons/location-pin.svg'

const defaultLocale = process.env.GATSBY_DEFAULT_LOCALE

function IconsBar({ locale, location }) {
  /** TODO: make the icons authorable as well as the link */
  const { mapPage, iconAltText } = useStoreLocatorMap(locale)

  return (
    <Wrapper locale={locale}>
      <Link
        href={`${
          extractLocaleFromUrl(location) !== defaultLocale
            ? `/${extractLocaleFromUrl(location)}`
            : ''
        }/${mapPage.slug}`}
      >
        <Icon src={locationPin} alt={iconAltText} />
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  line-height: 18.4px;
  width: 92px;
  justify-content: flex-end;

  ${above.tabletLarge`
      ${({ locale }) => (isRtl(locale) ? 'margin-left: 40px;' : 'margin-right: 40px;')}
  `};
`
const Link = styled.a`
  margin: 12px 0;
`
const Icon = styled.img`
  width: 26px;
  height: 26px;
`

export default IconsBar
