import React, { type PropsWithChildren } from 'react'
import classNames from 'classnames'
import { Link as GatsbyLink } from 'gatsby'
import sanitize from 'sanitize-filename'
import { usePageContext } from 'utils/src/pageContext'
import AppLinks from 'ui/src/AppLinks'
import { MaybeEmpty } from 'cw-frontend/src/types/utils'

export type IProps = {
  linkData: Partial<{
    content: {
      id: string
    }
    externalUrl: string
    appLinkUrl: string
    anchor: string
    assetSlug: string
    asset: MaybeEmpty<{
      file: MaybeEmpty<{
        fileName: string
        contentType: string
      }>
    }>
    accessibleDescription: string
  }>
  className?: string
  ariaLabel?: string
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}

export function ContentfulLink({
  linkData = {},
  className,
  ariaLabel,
  onClick,
  children,
}: PropsWithChildren<IProps>) {
  const {
    externalUrl,
    appLinkUrl,
    content,
    asset,
    assetSlug,
    anchor,
    accessibleDescription = '',
  } = linkData || {}
  const { allPages } = usePageContext()

  const commonProps = {
    className: classNames('link', className),
    title: accessibleDescription,
    onClick,
    'aria-label': ariaLabel,
  }

  if (content) {
    let link = `/${allPages[content.id]}`.replace('//', '/')
    link = anchor ? `${link}${anchor}` : link
    return (
      <GatsbyLink to={link} {...commonProps}>
        {/* @ts-expect-error Issue with type React.ReactNode used by GatsbyLink */}
        {children}
      </GatsbyLink>
    )
  }

  if (asset && asset.file?.contentType === 'application/pdf') {
    const slug = assetSlug ? `${sanitize(assetSlug).replace(/ /g, '-')}.pdf` : asset.file?.fileName
    const prefix = [undefined, '/'].includes(process.env.GATSBY_PATH_PREFIX)
      ? ''
      : `/${process.env.GATSBY_PATH_PREFIX}`
    return (
      <a href={`${prefix}/docs/${slug}`} target="_blank" rel="noreferrer" {...commonProps}>
        {children}
      </a>
    )
  }

  if (externalUrl) {
    return (
      <a href={externalUrl} target="_blank" rel="noreferrer" {...commonProps}>
        {children}
      </a>
    )
  }

  if (appLinkUrl) {
    return <AppLinks url={appLinkUrl} linkTitle={accessibleDescription} />
  }

  if (anchor) {
    return (
      <a href={`#${anchor}`} {...commonProps}>
        {children}
      </a>
    )
  }

  return null
}
