import React, { type PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import classNames from 'classnames'
import { color, above, costaTextBold, below, xsmallBodyTextBold } from 'ui/src/styles/index'
import { ContentfulLink, type IProps as ContentfulLinkProps } from '../../utils/link'

export interface IProps {
  alignment?: string
  ctaStyle?: string
  link: ContentfulLinkProps['linkData']
  customColor?: string
  customLinkColor?: string
  className?: string
  grid2x2?: string
}

function LinkCta({
  alignment,
  ctaStyle,
  link,
  customColor,
  customLinkColor,
  className,
  children,
  grid2x2,
}: PropsWithChildren<IProps>) {
  return (
    <StyledBlockLink
      className={classNames('linkCta', className)}
      customColor={customColor}
      customLinkColor={customLinkColor}
      grid2x2={grid2x2}
      variant={ctaStyle || 'button'}
      alignment={alignment || 'left'}
    >
      <ContentfulLink linkData={link}>{children}</ContentfulLink>
    </StyledBlockLink>
  )
}

const applyVariant = ({
  variant,
  customColor,
  customLinkColor,
}: {
  variant: IProps['ctaStyle']
  customColor?: string
  customLinkColor?: string
  grid2x2?: string
  alignment: string
}) => css`
  ${variant === 'button' &&
  css`
    .link {
      pointer-events: auto;
      background: ${customColor === 'white' ? color.white : color.costaRed};
      color: ${customColor === 'white' ? color.costaRed : color.white};
      padding: 15px 20px;
      border-radius: 32px;
      text-decoration: none;

      ${above.tablet`
        padding: 18px 27px;
      `}

      &:hover {
        background: ${color.lightRed};
        color: ${color.white};
        &:after {
          border-left: 6px solid ${color.white};
        }
      }

      &:after {
        border-left: 6px solid ${customColor === 'white' ? color.costaRed : color.white};
      }
    }
  `}
  ${variant === 'link' &&
  css`
    .link {
      background: none;
      color: ${customLinkColor === 'white' ? color.white : color.costaRed};
      padding: 15px 0;
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 4px;

      ${above.tablet`
        padding: 18px 0;
      `}

      &:after {
        border-left: 6px solid ${customLinkColor === 'white' ? color.white : color.costaRed};
      }

      &:hover {
        color: ${color.lightRed};
        &:after {
          border-left: 6px solid ${color.lightRed};
        }
      }
    }
  `}

  ${variant === 'in-page-button' &&
  css`
    .link {
      background-color: ${customColor === 'white' ? 'transparent' : color.white};
      border: ${customColor === 'white' ? color.white : color.costaRed} solid;
      border-radius: 50px;
      color: ${customColor === 'white' ? color.white : color.costaRed};
      padding: 12px 20px;
      text-decoration: none;

      ${above.tablet`
        padding: 15px 27px;
      `}

      &:after {
        content: none;
      }

      &:is(:hover, :focus) {
        background-color: ${color.lightRed};
        border-color: ${color.lightRed};
        color: ${color.white};
      }
    }
  `}
`

interface StyledBlockLinkProps {
  grid2x2?: string
  customColor?: string
  customLinkColor?: string
  alignment: string
  variant: string
}

const StyledBlockLink = styled.span<StyledBlockLinkProps>`
  display: block;

  .link {
    ${costaTextBold}
    display: inline-flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1px;
    cursor: pointer;
    margin-bottom: 16px;

    ${below.mobile`
      ${({ grid2x2 }: StyledBlockLinkProps) =>
        grid2x2 && grid2x2 === '2x2' ? `${xsmallBodyTextBold};` : `${costaTextBold};`}; 
    `}

    ${above.tablet`
      margin-bottom: 18px;
    `}

    &:after {
      content: '';
      display: inline-block;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      margin-left: 16px;

      ${above.tablet`
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
      `}
    }
  }

  ${({ alignment }) => css`
    text-align: ${alignment};
  `}

  ${props => applyVariant(props)};
`

export default LinkCta
