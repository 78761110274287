import axios from 'axios'
import resolveResponse from 'contentful-resolve-response'

import { format, set } from 'date-fns'
import settingsHelper from 'utils/src/settings'
import environmentsHelper from 'utils/src/environments'
import filters from './getFilterList'

import storeIcon from '../assets/icons/ukSiteTypes/store.svg'
import expressIcon from '../assets/icons/ukSiteTypes/express.svg'
import expressIcedIcon from '../assets/icons/ukSiteTypes/expressIced.svg'

const deg2rad = deg => deg * (Math.PI / 180)

export const storeHasHours = hours => hours && Object.values(hours).some(day => day.open)

export const getStoreMarker = store => {
  switch (store.storeType) {
    case 'COSTA EXPRESS':
      return store.facilities.expressIcedDrinks ? expressIcedIcon : expressIcon
    default:
      return storeIcon
  }
}

const storeFiltersForMarket = filters[process.env.GATSBY_BUDDY_PIPELINE || 'uk']

export const isServiceShowable = facility =>
  facility.active &&
  storeFiltersForMarket.storeCardFacilities.find(element => element.name === facility.name)

export const isAmenityShowable = facility =>
  facility.active &&
  storeFiltersForMarket.storeCardAmenities.find(element => element.name === facility.name)

export const getAddressString = addressObj => {
  if (!addressObj) return ''

  const addressParts = [
    addressObj.address1,
    addressObj.address2,
    addressObj.city,
    addressObj.postCode,
  ]

  return addressParts.filter(part => part).join(', ')
}

export const getWalkingTime = time => {
  const toMinutes = 0.0166667
  const timeInMinutes = time * toMinutes
  return Math.round(timeInMinutes)
}

export const getContentfulStoreDistanceMiles = (lat1, lon1, lat2, lon2) => {
  const floatLat1 = Number.parseFloat(lat1)
  const floatLon1 = Number.parseFloat(lon1)
  const floatLat2 = Number.parseFloat(lat2)
  const floatLon2 = Number.parseFloat(lon2)

  if (
    Number.isNaN(floatLat1 + floatLon1 + floatLat2 + floatLon2) ||
    typeof (floatLat1 + floatLon1 + floatLat2 + floatLon2) !== 'number'
  ) {
    return null
  }

  const R = 6371
  const dLat = deg2rad(floatLat2 - floatLat1)
  const dLon = deg2rad(floatLon2 - floatLon1)
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
  const b =
    Math.cos(deg2rad(floatLat1)) *
    Math.cos(deg2rad(floatLat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2)
  const e = a + b
  const c = 2 * Math.atan2(Math.sqrt(e), Math.sqrt(1 - e))
  const d = R * c
  return (d / 1.609344).toFixed(2)
}

export const formatDisplayTime = (time, timeFormat) => {
  if (!time) return ''
  const dateTime = set(new Date(), {
    hours: time.split(':')[0],
    minutes: time.split(':')[1],
  })
  return timeFormat === '12' ? format(dateTime, 'h:mm a') : format(dateTime, 'HH:mm')
}

export const getDistance = (distance, unit = 'imperial') => {
  if (unit === 'metric') {
    return `${(distance * 1.60934).toFixed(1)} km`
  }

  return `${distance.toFixed(1)} mi`
}

const transformSparFacilityName = key => {
  const dictionary = {
    delivery: 'Delivery',
    collect: 'Pay&Collect',
    driveThru: 'Drive Thru',
    wifi: 'Wifi',
    babyChanging: 'Baby Changing',
    disabledAccess: 'Disabled Access',
    disabledWC: 'Disabled WC',
    expressIcedDrinks: 'Iced Drinks',
  }

  return dictionary?.[key]
}

const transformSparFacilities = store =>
  Object.entries(store.facilities)
    .map(([facilityKey, facilityActive]) => ({
      name: transformSparFacilityName(facilityKey),
      active: !!facilityActive,
    }))
    .filter(f => f.name)

const transformSparStoreType = store => {
  return store.siteType === 'Global Express' ? 'COSTA EXPRESS' : 'COSTA STORE'
}

/**
 * Defaults to London when lat/lng env vars cannot be parsed
 */
export const getDefaultCenter = () => ({
  lat: Number.parseFloat(process.env.GATSBY_DEFAULT_LAT) || 51.504831314,
  lng: Number.parseFloat(process.env.GATSBY_DEFAULT_LNG) || -0.123499506,
})

export const getStores = async ({ lat, lng, locale }) => {
  const environment = environmentsHelper.getEnvironment()
  const country = process.env.GATSBY_BUDDY_PIPELINE || 'uk'
  const url = settingsHelper.getBaseUrl(environment, [country, 'stores'], ['default', 'stores'])

  if (country === 'uk') {
    return getSparStores(url, lat, lng)
  }

  return getContentfulStores(url, lat, lng, locale)
}

const getSparStores = async (url, lat, lng) => {
  const response = await axios({
    url,
    method: 'POST',
    credentials: 'same-origin',
    headers: { 'content-type': 'application/json' },
    data: {
      query: `
      query Sites {
        sites(
          siteStatuses: ["OPEN"]
          tradingStatusAvailable: true
          geo: { latitude: ${lat}, longitude: ${lng} }
          countries: "GB"
          orderBy: { distance: ASC }
          first: 50
        ){
          items {
            id
            name
            location {
              address {
                address1
                address2
                city
                postCode
              }
              geo {
                  latitude
                  longitude
                  distanceMiles
              }
            }
            siteType
            facilities {
              babyChanging
              clickAndServe
              coffeeClub
              collect
              delivery
              disabledAccess
              disabledWC
              driveThru
              giftCard
              preOrderCollect
              tooGoodToGo
              wifi
            }
            expressMachines {
              characteristics {
                icedDrinks
              }
            }
            operatingHours(timeTypes: ["Standard"]) {
              monday {
                  open24Hours
                  open
                  close
              }
              tuesday {
                open24Hours
                open
                close
              }
              wednesday {
                open24Hours
                open
                close
              }
              thursday {
                open24Hours
                open
                close
              }
              friday {
                open24Hours
                open
                close
              }
              saturday {
                open24Hours
                open
                close
              }
              sunday {
                open24Hours
                open
                close
              }
            }
          knownAs
          }
        }
      }
      `,
    },
  })

  if (response.data.errors) {
    const message = response.data.errors.map(error => error.message).join('\n')
    throw new Error(message)
  }

  return transformSparStores(response.data.data.sites.items)
}

const getContentfulStores = async (baseUrl, lat, lng, locale) => {
  const response = await axios({
    url: `${baseUrl}?locale=${locale}&include=2&content_type=storeLocatorStore&limit=500&fields.location[near]=${lat},${lng}`,
    method: 'GET',
  })

  return transformContentfulStores(resolveResponse(response.data), lat, lng)
}

const transformSparStores = stores =>
  stores.map(store => {
    // we store if the store has express iced drinks as a facility because that's how our filtering code works!
    // It won't show up on the store card though unless it's added to storeCardFacilities or storeCardAmenities :)
    store.facilities.expressIcedDrinks = store?.expressMachines?.[0]?.characteristics?.icedDrinks

    // this has to come before getStoreMarker below
    store.storeType = transformSparStoreType(store)

    return {
      ...store,
      name: store.knownAs || store.name,
      facilities: transformSparFacilities(store),
      operatingHours: store.operatingHours[0],
      storeTypeIcon: getStoreMarker(store),
    }
  })

const transformContentfulStores = (stores, lat, lng) =>
  stores.map(store => ({
    ...store,
    id: store.sys.id,
    name: store.fields.storeName,
    facilities: [],
    storeType: store.fields.storeType.fields.name,
    storeTypeIcon: store.fields.storeType.fields.icon.fields.file.url,
    storeTypeIconAlt: store.fields.storeType.fields.icon.fields.title,
    location: {
      address: {
        address1: store.fields.storeAddress,
        address2: '',
        city: '',
        postCode: '',
      },
      geo: {
        longitude: store.fields.location.lon,
        latitude: store.fields.location.lat,
        distanceMiles: Number.parseFloat(
          getContentfulStoreDistanceMiles(
            lat,
            lng,
            store.fields.location.lat,
            store.fields.location.lon
          )
        ),
      },
    },
    operatingHours: {
      monday: {
        open24Hours: store.fields.mondayOpening === 'Open 24 hours',
        open: store.fields.mondayOpening,
        close: store.fields.mondayClosing,
      },
      tuesday: {
        open24Hours: store.fields.tuesdayOpening === 'Open 24 hours',
        open: store.fields.tuesdayOpening,
        close: store.fields.tuesdayClosing,
      },
      wednesday: {
        open24Hours: store.fields.wednesdayOpening === 'Open 24 hours',
        open: store.fields.wednesdayOpening,
        close: store.fields.wednesdayClosing,
      },
      thursday: {
        open24Hours: store.fields.thursdayOpening === 'Open 24 hours',
        open: store.fields.thursdayOpening,
        close: store.fields.thursdayClosing,
      },
      friday: {
        open24Hours: store.fields.fridayOpening === 'Open 24 hours',
        open: store.fields.fridayOpening,
        close: store.fields.fridayClosing,
      },
      saturday: {
        open24Hours: store.fields.saturdayOpening === 'Open 24 hours',
        open: store.fields.saturdayOpening,
        close: store.fields.saturdayClosing,
      },
      sunday: {
        open24Hours: store.fields.sundayOpening === 'Open 24 hours',
        open: store.fields.sundayOpening,
        close: store.fields.sundayClosing,
      },
    },
    richText: store.fields.storeCardRichText,
    storeCardImage: store.fields.storeCardImage,
  }))
