import React, { useEffect, useReducer } from 'react'
import styled from 'styled-components'

import { getMicrocopy, useMicrocopy } from 'utils/src/microcopy'
import { above } from '../styles'
import LargeStoreLocatorMapRegion from './LargeStoreLocatorMapRegion/LargeStoreLocatorMapRegion'
import SmallStoreLocatorMapRegion from './SmallStoreLocatorMapRegion/SmallStoreLocatorMapRegion'

import { StoreLocatorContext, reducers, initialState } from '../reducers/combineReducers'
import { getDefaultCenter, getStores } from '../helpers/stores'
import { getCenterFromQueryString, setUserLocation } from '../helpers/requestLocationHelpers'

function StoreLocatorMapRegion({ node_locale }) {
  const [state, dispatch] = useReducer(reducers, initialState)
  const data = useMicrocopy(node_locale)
  const unit = getMicrocopy({ key: 'storeLocatorMap.DistanceUnit', data })
  const defaultCenter = getDefaultCenter()

  useEffect(() => {
    const queryCenter = getCenterFromQueryString()
    switch (process.env.GATSBY_BUDDY_PIPELINE) {
      case 'uk':
        setUserLocation(
          dispatch,
          queryCenter?.lat || defaultCenter.lat,
          queryCenter?.lng || defaultCenter.lng,
          unit
        )
        break

      default:
        getStores({
          lat: queryCenter?.lat || defaultCenter.lat,
          lng: queryCenter?.lng || defaultCenter.lng,
          locale: node_locale,
          unit,
        }).then(results => {
          dispatch({ type: 'setStores', payload: results })
          dispatch({ type: 'setAllStores', payload: results })
          if (queryCenter) {
            setUserLocation(dispatch, queryCenter.lat, queryCenter.lng, unit)
          } else if (results[0]) {
            setUserLocation(
              dispatch,
              Number.parseFloat(results[0].location.geo.latitude),
              Number.parseFloat(results[0].location.geo.longitude),
              unit
            )
          }
          new URLSearchParams(window.location.href).get('filter') &&
            dispatch({
              type: 'setStores',
              payload: results.filter(store => {
                return store.storeType === new URLSearchParams(window.location.search).get('filter')
              }),
            })
          new URLSearchParams(window.location.href).get('filter') &&
            dispatch({ type: 'setActiveFilterCount', payload: 1 })
        })
        break
    }
  }, [])

  // use the open query parameter
  useEffect(() => {
    const closestStore = state.stores.allStores[0]
    if (new URLSearchParams(window.location.search).get('open') === '1' && closestStore) {
      dispatch({ type: 'setSelectedStore', payload: closestStore })
      dispatch({ type: 'showStoreCard', payload: true })
    }
  }, [state.stores.allStores])

  // add/remove the open query parameter
  useEffect(() => {
    const url = new URL(window.location.href)
    if (state.app.showStoreCard) {
      url.searchParams.set('open', '1')
    } else if (state.stores.allStores[0]) {
      // state.stores.allStores[0] will be undefined on page load so we use this to prevent removing the parameter before it has been used
      url.searchParams.delete('open')
    }
    window.history.pushState({}, '', url.href)
  }, [state.app.showStoreCard])

  return (
    <StoreLocatorContext.Provider value={[state, dispatch]}>
      <TabletView>
        <LargeStoreLocatorMapRegion locale={node_locale} />
      </TabletView>
      <MobileView>
        <SmallStoreLocatorMapRegion locale={node_locale} />
      </MobileView>
    </StoreLocatorContext.Provider>
  )
}

const TabletView = styled.div`
  display: none;
  position: relative;

  ${above.tablet`
    display: block;
  `}
`

const MobileView = styled.div`
  display: block;
  position: relative;
  width: 100%;

  ${above.tablet`
    display: none;
  `}
`

export default StoreLocatorMapRegion
