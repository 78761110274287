import React from 'react'
import environmentsHelper from 'utils/src/environments'
import cookieHelper from './legacy/cookie'
import apiHelper from './legacy/account'
import type { Microcopy } from 'utils/src/microcopy'

// TODO: remove path prefix check once migrated

interface AccountOptionsProps {
  microcopy: Microcopy
}

export const AccountOptions = (props: AccountOptionsProps) => {
  const { microcopy } = props

  return document.cookie.match(/username=\w/) ? (
    <>
      <a
        href={`/${
          !process.env.GATSBY_PATH_PREFIX || process.env.GATSBY_PATH_PREFIX === '/'
            ? 'costa-club/account-home'
            : `${process.env.GATSBY_PATH_PREFIX}/costa-club/account-home`
        }`}
      >
        {microcopy.account.myAccountLabel}
      </a>
      <a
        href={`/${
          !process.env.GATSBY_PATH_PREFIX || process.env.GATSBY_PATH_PREFIX === '/'
            ? 'costa-club/login'
            : `${process.env.GATSBY_PATH_PREFIX}/costa-club/login`
        }`}
        onClick={() => {
          const userToken = cookieHelper.getCookie('ASP.NET_SessionId')
          const environment = environmentsHelper.getEnvironment()
          apiHelper.logOut(environment, userToken)
          document.cookie = 'username=; max-age=0; expires=; path=/; Secure;'
          document.cookie = 'ASP.NET_SessionId=; max-age=0; expires=; path=/; Secure;'
        }}
      >
        {microcopy.account.logoutLabel}
      </a>
    </>
  ) : (
    <a
      href={`/${
        !process.env.GATSBY_PATH_PREFIX || process.env.GATSBY_PATH_PREFIX === '/'
          ? 'costa-club/login'
          : `${process.env.GATSBY_PATH_PREFIX}/costa-club/login`
      }`}
    >
      {microcopy.account.loginLabel}
    </a>
  )
}
