import { TrackJS } from 'trackjs'
import pkg from '../../package.json'
import { wrapMsalProvider } from './src/utils/msal/wrapMsalProvider'

/**
 *
 * Once B2C is live this should be replaced with:
 * export const wrapRootElement = wrapMsalProvider
 */
export const wrapRootElement = process.env.GATSBY_B2C_ENABLED === 'true' ? wrapMsalProvider : null

const { version } = pkg
const token = process.env.GATSBY_TRACKJS_TOKEN
const application = process.env.GATSBY_TRACKJS_APPLICATION
const country = process.env.GATSBY_BUDDY_PIPELINE || 'unknown'
const project = process.env.GATSBY_REPO || 'unknown'

if (application) {
  TrackJS.install({
    token,
    application,
    version,
    onError(payload) {
      /**
       * If the project is not 'core', this code block will anonymize any email addresses
       * found in certain properties of the payload object. Currently this only effects `uk`
       * as this is the only country that has the notion of users.
       */
      if (project !== 'core') {
        const emailRegEx = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
        const removeEmail = string => string.replace(emailRegEx, 'xxx@xxx.xxx')
        payload.environment.originalUrl = removeEmail(payload.environment.originalUrl)
        payload.message = removeEmail(payload.message)
        payload.url = removeEmail(payload.url)
        payload.network.forEach((event, i) => {
          payload.network[i].url = removeEmail(event.url)
        })
      }
      return true
    },
  })
  TrackJS.addMetadata('package', `cw-gatsby-${project}`)
  TrackJS.addMetadata('market', country)
}
