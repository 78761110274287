import React, { useContext } from 'react'

import styled from 'styled-components'

import { getMicrocopy, useMicrocopy } from 'utils/src/microcopy'
import Toggle from 'ui/src/legacy/Toggle/toggle'
import StoreLocatorMap from '../../StoreLocatorMap/storeLocatorMap'
import StoreFilters from '../../StoreFilters/storeFilters'
import StoreCard from '../../StoreCard/storeCard'
import { StoreLocatorContext } from '../../reducers/combineReducers'
import StoreFiltersClosed from '../../StoreFiltersClosed/storeFiltersClosed'
import StoreLocatorSearchBar from '../../StoreLocatorSearchBar/storeLocatorSearchBar'
import StoreCardList from '../../StoreCardList/storeCardList'
import UserLocationButton from '../../UserLocationButton/userLocationButton'
import StoreCardNoResults from '../../StoreCardNoResults/storeCardNoResults'
import { getUserLocation, createDirectionsLink } from '../../helpers/requestLocationHelpers'
import { getDistance, getAddressString, getWalkingTime, getStores } from '../../helpers/stores'
import { useStoreLocatorMap } from '@cw-monorepo/contentful/src/hooks/useStoreLocatorMap'

import { above, color } from '../../styles'
import SearchThisAreaContainer from '../../SearchThisAreaContainer/searchThisAreaContainer'

function LargeStoreLocatorMapRegion({ className, locale }) {
  const [state, dispatch] = useContext(StoreLocatorContext)
  const data = useMicrocopy(locale)
  const { storeTypes, storeCardSecondButtonLink, storeTimesFormat } = useStoreLocatorMap(locale)
  const { app, location, stores } = state
  const unit = getMicrocopy({ key: 'storeLocatorMap.DistanceUnit', data })
  const dataCyScreenSize = 'desktop'
  const urlFilter = typeof window !== 'undefined' ? new URL(window.location.href) : null

  function isEmpty(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  return (
    <>
      <SidebarToggle
        className={className}
        toggleWidget={() => {
          dispatch({ type: 'showSideBar', payload: !app.showSideBar })
        }}
        toggled={!app.showSideBar}
        toggleAlt={getMicrocopy({ key: 'storeLocatorMap.FiltersApplyBtnLabel', data })}
      />
      {app.showSideBar && (
        <SideBar data-cy="sidebar">
          {app.showFilters && (
            <StoreFilters
              storeTypes={storeTypes}
              clearBtnLabel={getMicrocopy({
                key: 'storeLocatorMap.FiltersClearButtonLabel',
                data,
              })}
              closeBtnLabel={getMicrocopy({
                key: 'storeLocatorMap.FiltersCloseButtonLabel',
                data,
              })}
              storeFiltersHeading={getMicrocopy({
                key: 'storeLocatorMap.FiltersStoreFiltersHeading',
                data,
              })}
              storeFiltersLegend={getMicrocopy({
                key: 'storeLocatorMap.FiltersStoreFiltersLegend',
                data,
              })}
              applyBtnLabel={getMicrocopy({
                key: 'storeLocatorMap.FiltersApplyBtnLabel',
                data,
              })}
              noResults={getMicrocopy({
                key: 'storeLocatorMap.FiltersNoResultText',
                data,
              })}
              handleChecked={() => {}}
              invalid={false}
              country={process.env.GATSBY_BUDDY_PIPELINE || 'uk'}
              handleClear={() => {}}
              handleClose={() => {
                dispatch({ type: 'showFilters', payload: false })
              }}
              handleApplyFilters={() => {}}
              iconAlt="iconalt"
              dataCyScreenSize={dataCyScreenSize}
            />
          )}
          {app.showStoreCard && (
            <StoreCard
              storeOpenText={getMicrocopy({ key: 'storeLocatorMap.StoreOpenText', data })}
              opensText={getMicrocopy({ key: 'storeLocatorMap.StoreOpensText', data })}
              closesText={getMicrocopy({ key: 'storeLocatorMap.StoreClosesText', data })}
              storeClosedText={getMicrocopy({ key: 'storeLocatorMap.StoreClosedText', data })}
              openTomorrowText={getMicrocopy({
                key: 'storeLocatorMap.StoreOpenTomorrowText',
                data,
              })}
              storeOpen24HoursText={getMicrocopy({
                key: 'storeLocatorMap.StoreOpen24HoursText',
                data,
              })}
              store24HoursText={getMicrocopy({
                key: 'storeLocatorMap.Store24HoursText',
                data,
              })}
              crossIconAlt={getMicrocopy({ key: 'storeLocatorMap.StoreCardCrossAlt', data })}
              heading={stores.selectedStore.name}
              icon={stores.selectedStore.storeTypeIcon}
              iconAlt={stores.selectedStore.storeTypeIconAlt}
              address={getAddressString(stores.selectedStore.location.address)}
              addressIconAlt={getMicrocopy({
                key: 'storeLocatorMap.StoreCardAddressIconAlt',
                data,
              })}
              showWalkingDistance={!isEmpty(location.userLocation)}
              distance={getDistance(stores.selectedStore.location.geo.distanceMiles, unit)}
              distanceIconAlt={getMicrocopy({
                key: 'storeLocatorMap.StoreCardDistanceIconAlt',
                data,
              })}
              user={location.userLocation}
              servicesText={getMicrocopy({ key: 'storeLocatorMap.StoreCardServicesText', data })}
              amenitiesText={getMicrocopy({ key: 'storeLocatorMap.StoreCardAmenitiesText', data })}
              walkText={getMicrocopy({
                key: 'storeLocatorMap.StoreCardWalkText',
                data,
              })}
              latitude={stores.selectedStore.location.geo.latitude}
              longitude={stores.selectedStore.location.geo.longitude}
              duration={
                stores.selectedStore.duration
                  ? getWalkingTime(stores.selectedStore.duration.value)
                  : ''
              }
              facilities={stores.selectedStore.facilities}
              storeCardImage={stores.selectedStore.storeCardImage}
              richText={stores.selectedStore.richText}
              directionCTA={{
                name: getMicrocopy({ key: 'storeLocatorMap.StoreCardDirectionsButton', data }),
                link: createDirectionsLink(
                  {
                    lat: stores.selectedStore.location.geo.latitude,
                    lng: stores.selectedStore.location.geo.longitude,
                  },
                  { lat: location.userLocation.latitude, lng: location.userLocation.longitude }
                ),
              }}
              orderCTA={{
                name: getMicrocopy({ key: 'storeLocatorMap.StoreCardSecondButtonName', data }),
                link: storeCardSecondButtonLink,
              }}
              backButtonText={getMicrocopy({
                key: 'storeLocatorMap.StoreCardBackButtonText',
                data,
              })}
              backButtonOnClick={() => {
                dispatch({ type: 'showStoreCard', payload: false })
              }}
              storeTimes={stores.selectedStore.operatingHours}
              storeTimesFormat={storeTimesFormat}
              monday={getMicrocopy({ key: 'storeLocatorMap.monday', data })}
              tuesday={getMicrocopy({ key: 'storeLocatorMap.tuesday', data })}
              wednesday={getMicrocopy({ key: 'storeLocatorMap.wednesday', data })}
              thursday={getMicrocopy({ key: 'storeLocatorMap.thursday', data })}
              friday={getMicrocopy({ key: 'storeLocatorMap.friday', data })}
              saturday={getMicrocopy({ key: 'storeLocatorMap.saturday', data })}
              sunday={getMicrocopy({ key: 'storeLocatorMap.sunday', data })}
              dataCyScreenSize={dataCyScreenSize}
              type={stores.selectedStore.storeType}
            />
          )}

          {!app.showFilters && !app.showStoreCard && (
            <>
              <FiltersAndSearch>
                <FilterButton
                  iconAlt={getMicrocopy({ key: 'storeLocatorMap.FilterButtonIconAlt', data })}
                  btnAriaLabel={getMicrocopy({
                    key: 'storeLocatorMap.FilterButtonAriaLabel',
                    data,
                  })}
                  activeFiltersCount={app.activeFilterCount}
                  disabled={false}
                  handleFiltersClick={() => {
                    dispatch({ type: 'showFilters', payload: !app.showFilters })
                  }}
                  dataCyScreenSize={dataCyScreenSize}
                />
                <FullWidthSearchBar
                  locale={locale}
                  placeholder={getMicrocopy({
                    key: 'storeLocatorMap.SearchBarPlaceholder',
                    data,
                  })}
                  btnText=""
                  btnAriaLabel={getMicrocopy({
                    key: 'storeLocatorMap.SearchBarButtonAriaLabel',
                    data,
                  })}
                  inputAriaLabel={getMicrocopy({
                    key: 'storeLocatorMap.SearchBarInputAriaLabel',
                    data,
                  })}
                  name="textInput"
                  apiKey={process.env.GATSBY_GOOGLE_API_KEY}
                  setLocation={locationToSet => {
                    urlFilter.searchParams.delete('filter')
                    window.history.replaceState(null, '', urlFilter.href)
                    dispatch({
                      type: 'setSelectedLocation',
                      payload: { lat: locationToSet.latitude, lng: locationToSet.longitude },
                    })
                    getStores({
                      lat: locationToSet.latitude,
                      lng: locationToSet.longitude,
                      locale,
                      unit,
                    }).then(results => {
                      dispatch({ type: 'clear' })
                      dispatch({ type: 'setActiveFilterCount', payload: 0 })
                      dispatch({ type: 'setStores', payload: results })
                      dispatch({ type: 'setAllStores', payload: results })
                      if (results[0]) {
                        dispatch({
                          type: 'setUserLocation',
                          payload: {
                            lat: Number.parseFloat(results[0].location.geo.latitude),
                            lng: Number.parseFloat(results[0].location.geo.longitude),
                          },
                        })
                      }
                    })
                  }}
                  setLoading={() => {
                    dispatch({ type: 'showChangeLocationButton', payload: false })
                  }}
                  dataCy="map-desktop"
                />
              </FiltersAndSearch>
              {stores.storesSet.length !== 0 ? (
                <ScrollableCardList data-cy="store-card-list">
                  <StoreCardList
                    draggableIconAlt={getMicrocopy({
                      key: 'storeLocatorMap.StoreCardListDraggableAlt',
                      data,
                    })}
                    draggabAriaLabel={getMicrocopy({
                      key: 'storeLocatorMap.StoreCardListDraggableAriaLabel',
                      data,
                    })}
                    locale={locale}
                    chevronAlt={getMicrocopy({
                      key: 'storeLocatorMap.StoreCardListChevronAlt',
                      data,
                    })}
                    stores={stores.storesSet}
                    storeTimesFormat={storeTimesFormat}
                    showWalkingDistance={!isEmpty(location.userLocation)}
                    storeOpenText={getMicrocopy({ key: 'storeLocatorMap.StoreOpenText', data })}
                    storeClosedText={getMicrocopy({ key: 'storeLocatorMap.StoreClosedText', data })}
                    openTomorrowText={getMicrocopy({
                      key: 'storeLocatorMap.StoreOpenTomorrowText',
                      data,
                    })}
                    store24HoursText={getMicrocopy({
                      key: 'storeLocatorMap.Store24HoursText',
                      data,
                    })}
                    storeOpen24HoursText={getMicrocopy({
                      key: 'storeLocatorMap.StoreOpen24HoursText',
                      data,
                    })}
                    mondayText={getMicrocopy({
                      key: 'storeLocatorMap.monday',
                      data,
                    })}
                    tuesdayText={getMicrocopy({
                      key: 'storeLocatorMap.tuesday',
                      data,
                    })}
                    wednesdayText={getMicrocopy({
                      key: 'storeLocatorMap.wednesday',
                      data,
                    })}
                    thursdayText={getMicrocopy({
                      key: 'storeLocatorMap.thursday',
                      data,
                    })}
                    fridayText={getMicrocopy({
                      key: 'storeLocatorMap.friday',
                      data,
                    })}
                    saturdayText={getMicrocopy({
                      key: 'storeLocatorMap.saturday',
                      data,
                    })}
                    sundayText={getMicrocopy({
                      key: 'storeLocatorMap.sunday',
                      data,
                    })}
                    iconAlt=""
                    opensText={getMicrocopy({ key: 'storeLocatorMap.StoreOpensText', data })}
                    closesText={getMicrocopy({ key: 'storeLocatorMap.StoreClosesText', data })}
                    user={location.userLocation}
                    unit={unit}
                    walkText={getMicrocopy({ key: 'storeLocatorMap.StoreCardWalkText', data })}
                    storeClick={store => {
                      dispatch({ type: 'showStoreCard', payload: !app.showStoreCard })
                      dispatch({ type: 'setSelectedStore', payload: store })
                      dispatch({
                        type: 'setSelectedLocation',
                        payload: {
                          lat: Number.parseFloat(store.location.geo.latitude),
                          lng: Number.parseFloat(store.location.geo.longitude),
                        },
                      })
                    }}
                    dataCyScreenSize={dataCyScreenSize}
                  />
                </ScrollableCardList>
              ) : (
                !app.loading && (
                  <StoreCardNoResults
                    dataCy={`store-card-${dataCyScreenSize}`}
                    title={getMicrocopy({ key: 'storeLocatorMap.StoreCardNoResultsTitle', data })}
                    subtitle={getMicrocopy({
                      key: 'storeLocatorMap.StoreCardNoResultsSubTitle',
                      data,
                    })}
                  />
                )
              )}
            </>
          )}
        </SideBar>
      )}
      {app.showChangeLocationButton && process.env.GATSBY_BUDDY_PIPELINE === 'uk' && (
        <SearchThisAreaStyled
          className={className}
          isSideBarClosed={!app.showSideBar}
          searchAreaButtonText={getMicrocopy({
            key: 'storeLocatorMap.searchThisAreaText',
            data,
          })}
          onClick={() => {
            urlFilter.searchParams.delete('filter')
            window.history.replaceState(null, '', urlFilter.href)
            dispatch({ type: 'showChangeLocationButton', payload: false })
            dispatch({
              type: 'setUserLocation',
              payload: { lat: location.movedMapLocation.lat, lng: location.movedMapLocation.lng },
            })
            dispatch({
              type: 'setSelectedLocation',
              payload: { lat: location.movedMapLocation.lat, lng: location.movedMapLocation.lng },
            })
            getStores({
              lat: location.movedMapLocation.lat,
              lng: location.movedMapLocation.lng,
              locale,
              unit,
            }).then(results => {
              dispatch({ type: 'clear' })
              dispatch({ type: 'setActiveFilterCount', payload: 0 })
              dispatch({ type: 'setStores', payload: results })
              dispatch({ type: 'setAllStores', payload: results })
              dispatch({ type: 'showStoreCard', payload: false })
            })
          }}
          dataCyScreenSize={dataCyScreenSize}
        />
      )}
      <UseLocationIconButton
        className={className}
        iconAlt={getMicrocopy({ key: 'storeLocatorMap.UserLocationIconAlt', data })}
        btnAriaLabel={getMicrocopy({ key: 'storeLocatorMap.UserLocationAriaLabel', data })}
        disabled={false}
        handleButtonClick={() => {
          urlFilter.searchParams.delete('filter')
          window.history.replaceState(null, '', urlFilter.href)
          dispatch({ type: 'showChangeLocationButton', payload: false })
          dispatch({ type: 'clear' })
          getUserLocation(dispatch, unit, locale)
        }}
        dataCy="map-desktop__"
      />
      <StoreLocatorMap
        zoom={14}
        apiKey={process.env.GATSBY_GOOGLE_API_KEY}
        mapCenter={location.selectedLocation}
        stores={stores.storesSet}
        dataCyScreenSize={dataCyScreenSize}
      />
    </>
  )
}

const SideBar = styled.div`
  position: absolute;
  z-index: 1;
  overflow: visible;
  background: ${color.white};
  padding: 20px 0 0px;
  height: 100%;
`

const SearchThisAreaStyled = styled(SearchThisAreaContainer)`
  position: absolute;
  display: flex;
  justify-content: center;

  ${above.tablet`
    ${({ isSideBarClosed }) => (isSideBarClosed ? 'left: 0; right: 0;' : /* 2/24 */ 'right: 8.3%')}
  `}

  ${above.tabletLarge`
    ${({ isSideBarClosed }) =>
      isSideBarClosed ? 'left: 0; right: 0;' : /* 5/24 */ 'right: 20.83%'};
  `}

  ${above.desktop`
    ${({ isSideBarClosed }) =>
      isSideBarClosed ? 'left: 0; right: 0;' : /* 7/24 */ 'right: 29.16%'};
  `}

  top: 40px;
`

const ScrollableCardList = styled.div`
  overflow-y: scroll;
  height: 88%;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
`

const UseLocationIconButton = styled(UserLocationButton)`
  position: relative;
  align-self: flex-end;
  margin: auto 12px 12px 0;
  pointer-events: all;

  ${above.tablet`
      right: 32px;
      bottom: 32px;
      position: fixed;
  `}
`

const SidebarToggle = styled(Toggle)`
  left: 449px;
  top: 40px;
`

const FiltersAndSearch = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 1;
  background: ${color.white};

  pointer-events: all;
  height: 48px;

  width: 425px;
  height: 69px;
  margin: 10px 12px 0;
`

const FilterButton = styled(StoreFiltersClosed)`
  margin-right: 12px;

  ${above.tablet`
    margin-right: 27px;
  `}
`

const FullWidthSearchBar = styled(StoreLocatorSearchBar)`
  width: 100%;
`

export default LargeStoreLocatorMapRegion
