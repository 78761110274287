export type Fields = {
  firstName: string
  surname: string
  email: string
  telephone?: string
  reason: string
  comments: string
  terms: boolean
  type: string
}

type FormConfig = {
  fields: Partial<Fields>
}

export const formConfig = (node_locale?: string): FormConfig => {
  //  Core fields to be included in all forms and their default values
  const coreFields: Fields = {
    firstName: '',
    surname: '',
    email: '',
    reason: '',
    comments: '',
    terms: false,
    type: 'consumer',
  }

  switch (node_locale) {
    case 'pl-PL':
      return {
        fields: { ...coreFields },
      }
    default:
      return {
        fields: {
          ...coreFields,
          telephone: '',
        },
      }
  }
}
