const cookieHelper = {
  areCookiesAllowed() {
    return !!document.cookie.match(/costa_cookieconsent=allow/)
  },
  setCookie(name, value, days) {
    let expires = ''
    if (days) {
      const date = new Date()
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
      expires = `; expires=${date.toUTCString()}`
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`
  },
  getCookie(name) {
    const regex = new RegExp(`${name}=([^;]*)`)
    const cookie = document.cookie.match(regex)
    return (cookie && cookie[1]) || null
  },
}

export default cookieHelper
