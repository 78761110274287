export const initialStoresState = {
  storesSet: [],
  allStores: [],
  selectedLocation: {},
  selectedStore: {},
}

export function storesReducer(state, action) {
  switch (action.type) {
    case 'setAllStores':
      return { ...state, allStores: action.payload }
    case 'setStores':
      return {
        ...state,
        storesSet: action.payload,
        selectedStore: action.payload[0],
        selectedLocation: {
          lat: Number.parseFloat(action.payload[0]?.location.geo.latitude),
          lng: Number.parseFloat(action.payload[0]?.location.geo.longitude),
        },
      }
    case 'getStores':
      return { stores: state.storesSet, activeFilterCount: state.activeFilterCount }
    case 'setSelectedStore':
      return { ...state, selectedStore: action.payload }
    case 'filterByQuery':
      return {
        ...state,
        storesSet: state.allStores.filter(store => store.storeType === action.payload),
      }
    default:
      return state
  }
}

export default { storesReducer, initialStoresState }
