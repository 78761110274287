import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { color, above } from 'ui/src/styles/index'
import GlobalStyles from 'ui/src/styles/global'
import LoadAdobe from '../components/LoadAdobe/LoadAdobe'
import { LoadGoogleTag } from '../components/LoadGoogleTag/loadGoogleTag'
import Header from '../components/Header/header'
import SecondaryNav from '../components/SecondaryNav/secondaryNav'
import Footer from '../components/Footer/footer'
import ConsentBannerWrapper from '../components/ConsentBanner/ConsentBannerWrapper'

import hotjar from '../../static/hotjar'

function Layout({
  hero,
  secondaryNavigation = false,
  location,
  noBackground = false,
  isStoreLocator = false,
  children,
  locale = undefined,
  alternativeLogo,
}) {
  const [allowTracking, setAllowTracking] = useState(false)

  const GTM_ID = process.env.GATSBY_GOOGLE_GTM || null
  const GA_TAG = process.env.GATSBY_GOOGLE_GA || null

  const isProd = process.env.GATSBY_DEPLOY_ENVIRONMENT === 'production'
  const loadHotjar =
    (process.env.GATSBY_HOTJAR_PROD === 'true' && isProd) ||
    (process.env.GATSBY_HOTJAR_DEV === 'true' && !isProd)

  const loadOneTrust =
    (process.env.GATSBY_ONETRUST_PROD === 'true' && isProd) ||
    (process.env.GATSBY_ONETRUST_DEV === 'true' && !isProd)

  const [hasDocument, setHasDocument] = useState(false)
  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => {
    if (typeof document !== 'undefined') {
      setHasDocument(true)
    }
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
  }, [])

  // if onetrust is enabled dont check the state, if its disabled check if allowTracking is true
  return (
    <>
      {(loadOneTrust || allowTracking) && (
        <>
          <LoadAdobe oneTrustEnabled={loadOneTrust} />
          {process.env.GATSBY_BUDDY_PIPELINE === 'uk' && GTM_ID && (
            <LoadGoogleTag id={GTM_ID} isManager />
          )}
          {loadHotjar && process.env.GATSBY_BUDDY_PIPELINE === 'uk' && (
            <Helmet>
              <script
                type={loadOneTrust ? 'text/plain' : 'text/javascript'}
                className="optanon-category-C0002"
              >
                {hasWindow &&
                  hasDocument &&
                  hotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=')}
              </script>
            </Helmet>
          )}
          {process.env.GATSBY_BUDDY_PIPELINE === 'uae' &&
            process.env.GATSBY_DEPLOY_ENVIRONMENT === 'production' && (
              <Helmet>
                <script type="text/plain" className="optanon-category-C0002">
                  {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', 694382481636771);
                fbq('track', 'PageView');
              `}
                </script>
                <noscript>
                  {`<img
                height="1"
                width="1"
                style="display:none"
                src="https://www.facebook.com/tr?694382481636771&ev=PageView&noscript=1"
              />`}
                </noscript>
              </Helmet>
            )}
          {process.env.GATSBY_BUDDY_PIPELINE === 'uk' && GA_TAG && <LoadGoogleTag id={GA_TAG} />}
          <noscript>
            {GTM_ID && (
              <iframe
                src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
                height="0"
                width="0"
                style={{ display: 'none', visibility: 'hidden' }}
                title={`gtm${GTM_ID}`}
                className="optanon-category-C0002"
              ></iframe>
            )}
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=AW-720406842"
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
              title="gtmAW-720406842"
              className="optanon-category-C0002"
            ></iframe>
          </noscript>
        </>
      )}

      <GlobalStyles noBackground={noBackground} bgColor={color.white} />
      {!loadOneTrust && (
        <ConsentBannerWrapper setAllowTracking={setAllowTracking} locale={locale} />
      )}
      <Header hero={hero} alternativeLogo={alternativeLogo} location={location} locale={locale} />
      {secondaryNavigation && secondaryNavigation.link && (
        <SecondaryNav links={secondaryNavigation.link} location={location} locale={locale} />
      )}
      <Main location={location} style={{ minHeight: '400px' }} isStoreLocator={isStoreLocator}>
        {children}
      </Main>
      {!isStoreLocator && <Footer locale={locale} />}
    </>
  )
}

const Main = styled.main`
  margin: ${p => (p.isStoreLocator ? '0px' : '50px auto')};
  max-width: ${p => (p.isStoreLocator ? '' : '1392px')};

  ${above.tablet`
    margin: ${p => (p.isStoreLocator ? '0px' : '92px auto')};
  `}
`

export default Layout
