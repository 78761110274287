import React, { useState } from 'react'
import styled from 'styled-components'
import { getMicrocopyObject, useMicrocopy } from 'utils/src/microcopy'

import Button from 'ui/src/Button'
import Textarea from 'ui/src/Textarea'
import Input from 'ui/src/Input'
import Select from 'ui/src/Select/Select'
import Form from 'ui/src/Form'
import FormField from 'ui/src/FormField'
import Heading from 'ui/src/Heading'
import Checkbox from 'ui/src/Checkbox/Checkbox'
import { baseBodyText } from 'ui/src/styles/index'
import suite from './validation'
import { submitContactForm } from '../../api/ContactFormApi'
import PopupCTA from '../../../PopupCTA/popupCTA'
import { formConfig } from './formConfig'

export interface IProps {
  node_locale?: string
  anchor?: string
}

function InternationalContactForm(props: IProps) {
  const { anchor, node_locale } = props
  const [loading, setLoading] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  const microcopy = getMicrocopyObject(useMicrocopy(node_locale))
  const { fields } = formConfig(node_locale)
  const [inputs, setInputs] = React.useState({ ...fields })

  /*
    In Contentful, microcopy.InternationalContactForm.reasonOptions should be a csv string. Each comma-separated value within this may optionally be followed by a square-bracketed email address, which if set will be used as the email destination for enquiries of that type rather than the default email address set as process.env.GATSBY_INTERNATIONAL_CONTACT_FORM_EMAIL.
  */
  const interpolatedEmailRegex = /[[\]]/
  const reasonOptions = microcopy.internationalContactForm.reasonOptions.split(',').map(r => ({
    label: r.trim().split(interpolatedEmailRegex)[0],
    value: r.trim().split(interpolatedEmailRegex)[0],
    email:
      r.trim().split(interpolatedEmailRegex)?.[1] ||
      process.env.GATSBY_INTERNATIONAL_CONTACT_FORM_EMAIL,
  }))

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    const result = suite(inputs, microcopy) // validate form
    setInputs({ ...inputs }) // force re-render to show errors

    if (!result.isValid()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      try {
        const receiverEmail =
          reasonOptions.find(option => option.value === inputs.reason)?.email || ''
        await submitContactForm(inputs, receiverEmail)
        setShowSuccess(true)
      } catch {
        setShowError(true)
      }
    }

    setLoading(false)
  }

  const onFieldChange = (fieldName: string, value: any) => {
    suite.resetField(fieldName)
    setInputs({ ...inputs, [fieldName]: value })
  }

  const validationResult = suite.get()

  return (
    <Form id={anchor} onSubmit={onSubmit}>
      <Heading dataCy="heading">{microcopy.internationalContactForm.heading}</Heading>

      {'firstName' in fields && (
        <FormField>
          <Input
            id="firstName"
            dataCy="input__first-name"
            label={microcopy.internationalContactForm.firstNameLabel}
            placeholder={microcopy.internationalContactForm.firstNamePlaceholder}
            value={inputs.firstName}
            error={validationResult.getErrors('firstName')[0]}
            type="text"
            onChange={value => onFieldChange('firstName', value)}
          />
        </FormField>
      )}

      {'surname' in fields && (
        <FormField>
          <Input
            id="surname"
            dataCy="input__surname"
            label={microcopy.internationalContactForm.surnameLabel}
            placeholder={microcopy.internationalContactForm.surnamePlaceholder}
            value={inputs.surname}
            error={validationResult.getErrors('surname')[0]}
            type="text"
            onChange={value => onFieldChange('surname', value)}
          />
        </FormField>
      )}

      {'email' in fields && (
        <FormField>
          <Input
            id="email"
            dataCy="input__email"
            label={microcopy.internationalContactForm.emailLabel}
            placeholder={microcopy.internationalContactForm.emailPlaceholder}
            value={inputs.email}
            error={validationResult.getErrors('email')[0]}
            type="email"
            onChange={value => onFieldChange('email', value)}
          />
        </FormField>
      )}

      {'telephone' in fields && (
        <FormField>
          <Input
            id="telephone"
            dataCy="input__telephone"
            label={microcopy.internationalContactForm.telephoneLabel}
            placeholder={microcopy.internationalContactForm.telephonePlaceholder}
            value={inputs.telephone}
            error={validationResult.getErrors('telephone')[0]}
            type="tel"
            onChange={value => onFieldChange('telephone', value)}
          />
        </FormField>
      )}

      {'reason' in fields && (
        <FormField>
          <Select
            id="reason"
            dataCy="select__reason"
            label={microcopy.internationalContactForm.reasonLabel}
            placeholder={microcopy.internationalContactForm.reasonPlaceholder}
            value={inputs.reason}
            error={validationResult.getErrors('reason')[0]}
            options={reasonOptions}
            onChange={value => onFieldChange('reason', value)}
          />
        </FormField>
      )}

      {'comments' in fields && (
        <FormField>
          <Textarea
            id="comments"
            dataCy="textarea__comments"
            label={microcopy.internationalContactForm.commentsLabel}
            placeholder={microcopy.internationalContactForm.commentsPlaceholder}
            value={inputs.comments}
            error={validationResult.getErrors('comments')[0]}
            onChange={value => onFieldChange('comments', value)}
          />
        </FormField>
      )}

      {'terms' in fields && (
        <FormField>
          <Checkbox
            id="terms"
            value={!!inputs.terms}
            error={validationResult.getErrors('terms')[0]}
            dataCy="input__terms"
            onChange={checked => onFieldChange('terms', checked)}
          >
            {microcopy.internationalContactForm.termsAndConditionsCheckboxLabel}
          </Checkbox>
        </FormField>
      )}

      <FormField>
        <StyledButton variant="primary" type="submit" icon="submit" loading={loading}>
          {microcopy.submitButtonText}
        </StyledButton>
      </FormField>

      <PopupCTA
        content={
          <div>
            <header>
              <h1>{microcopy.internationalContactForm.successPopupHeader}</h1>
            </header>
            <StyledMicrocopyParagraph>
              {microcopy.internationalContactForm.successPopupText}
            </StyledMicrocopyParagraph>
          </div>
        }
        buttonValue={microcopy.internationalContactForm.successPopupButtonText}
        click={() => {
          setShowSuccess(false)
        }}
        icon="back"
        visible={showSuccess}
        customStyles="popupHeader"
        type="button"
      />

      <PopupCTA
        content={
          <div>
            <header>
              <h1>{microcopy.internationalContactForm.errorPopupHeader}</h1>
            </header>
            <StyledMicrocopyParagraph>
              {microcopy.internationalContactForm.errorPopupText}
            </StyledMicrocopyParagraph>
          </div>
        }
        buttonValue={microcopy.internationalContactForm.errorPopupButtonText}
        click={() => {
          setShowError(false)
        }}
        icon="back"
        visible={showError}
        customStyles="popupHeader"
        type="button"
      />
    </Form>
  )
}

const StyledButton = styled(Button)`
  margin: 0 auto;
`

const StyledMicrocopyParagraph = styled.span`
  display: block;
  font-size: ${baseBodyText};
  margin: 1rem 0;
`

export default InternationalContactForm
