import axios from 'axios'
import settings from 'utils/src/settings'

const account = {
  login(environment, username, password) {
    const bearer = settings.getBearerToken(environment)

    const loginDetails = {
      username,
      password,
    }
    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(environment, ['loyalty', 'login'])}`
      axios({
        url,
        data: loginDetails,
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${bearer}`,
        },
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },

  logOut(environment, bearer) {
    const token = `Bearer ${bearer}`
    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(environment, ['loyalty', 'login'])}?fcmToken=costaWebUser`
      axios({
        url,
        method: 'DELETE',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'X-COSTA-CHANNEL': 'W',
          'X-COSTA-LANG': 'en-gb',
          'X-COSTA-COUNTRY': 'GB',
          Authorization: token,
        },
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(e => reject(e))
    })
  },

  resetPassword(body, environment) {
    const bearer = settings.getBearerToken(environment)

    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(environment, ['loyalty', 'login'])}/reset-password`

      axios({
        url,
        data: body,
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${bearer}`,
        },
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(e => reject(e))
    })
  },

  getUserPoints(bearer, environment) {
    const token = `Bearer ${bearer}`
    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(environment, ['loyalty', 'points'])}`

      axios({
        url,
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: token,
          'X-COSTA-CHANNEL': 'W',
          'X-COSTA-LANG': 'en-gb',
          'X-COSTA-COUNTRY': 'GB',
        },
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(e => reject(e))
    })
  },

  getUserRewardDrinks(bearer, environment) {
    const token = `Bearer ${bearer}`
    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(environment, ['loyalty', 'reward'])}`

      axios({
        url,
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: token,
          'X-COSTA-CHANNEL': 'W',
          'X-COSTA-LANG': 'en-gb',
          'X-COSTA-COUNTRY': 'GB',
        },
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(e => reject(e))
    })
  },

  getUserDetails(email, bearer, environment) {
    const token = `Bearer ${bearer}`
    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(environment, ['loyalty', 'profile'])}/${email}`

      axios({
        url,
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: token,
        },
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(e => reject(e))
    })
  },

  getTransactions(bearer, environment) {
    const token = `Bearer ${bearer}`
    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(environment, ['loyalty', 'transactions'])}`

      axios({
        url,
        method: 'GET',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: token,
          'X-COSTA-CHANNEL': 'W',
          'X-COSTA-LANG': 'en-gb',
          'X-COSTA-COUNTRY': 'GB',
        },
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(e => reject(e))
    })
  },

  changePassword(data) {
    const params = {
      params: [
        { op: 'test', path: '/email', value: `${data.email}` },
        {
          op: 'replace',
          path: '/password',
          value: `${data.newPassword.password}`,
          password: `${data.oldPassword.password}`,
        },
      ],
    }

    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(data.environment, ['loyalty', 'profile'])}/${data.email}`

      axios({
        url,
        data: params,
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${data.token}`,
        },
      })
        .then(res => resolve(res.data))
        .catch(e => reject(e))
    })
  },

  updatePreferences(environment, userDetails, permissionBody, token) {
    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(environment, ['loyalty', 'profile'])}/${
        userDetails.username
      }/permissions/`

      axios({
        url,
        data: permissionBody,
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Bearer ${token}`,
          'X-COSTA-CHANNEL': 'O',
          'X-COSTA-LANG': 'en-gb',
          'X-COSTA-COUNTRY': 'GB',
        },
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(e => reject(e))
    })
  },

  updateEmailAddress(data, environment) {
    const token = `Bearer ${data.token}`

    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(environment, ['loyalty', 'profile'])}/${
        data.body.params[0].value
      }`

      axios({
        url,
        data: data.body,
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: token,
        },
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(e => reject(e))
    })
  },
  updateBasicUserInfo(data, username, authToken, environment) {
    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(environment, ['loyalty', 'profile'])}/${username}`

      axios({
        url,
        data,
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${authToken}`,
        },
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(e => reject(e))
    })
  },

  updateNewCardNumber(newCardValue, oldCardNumber, token, environment) {
    const bodyData = {
      newCardType: 'physical',
      givexNumber: newCardValue,
    }

    return new Promise((resolve, reject) => {
      const url = `${settings.getBaseUrl(environment, [
        'loyalty',
        'lostCard',
      ])}/${oldCardNumber}/status/lost`

      axios({
        url,
        data: bodyData,
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          resolve(res.data)
        })
        .catch(e => reject(e))
    })
  },
}

export default account
